import L from "leaflet";
import markerIcon from "../images/robot-marker.png";
import markerIconGrey from "../images/robot-marker-grey.png";
import pointMarkerIcon from "../images/marker-icon.png";
import endMarkerIcon from "../images/end-marker.png";

export const activeGroverIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [42, 42],
    popupAnchor: [0, -20],
});

export const inactiveGroverIcon = L.icon({
    iconUrl: markerIconGrey,
    iconSize: [42, 42],
    popupAnchor: [0, -20],
});

export const pointMarker = L.icon({
    iconUrl: pointMarkerIcon,
    iconSize: [42, 50],
    popupAnchor: [0, -20],
});

export const endMarker = L.icon({
    iconUrl: endMarkerIcon,
    iconSize: [26, 34],
    popupAnchor: [0, -20],
});

