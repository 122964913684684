import React from "react";
import { useMap } from "react-leaflet";

const ChangeMapCenter = props => {
    const { center, zoom, follow } = props;
    const map = useMap();

    if (follow) {
        map.setView(center, zoom || map.getZoom(), {
            animate: true,
        });
    }

    return null;
};

export default ChangeMapCenter;