import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { cognitoConfig } from "../config/aws.config";
import { getTokenFromUrl, jumpToLogin } from "../utils/login";
import { updateToken } from "../store/features/authSlice";

const useValidateToken = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const { access_token } = getTokenFromUrl();
        validateToken(access_token)
            .then(res => {
                dispatch(updateToken(res));
            })
            .catch(() => {
                jumpToLogin();
            });
    }, []);

    const validateToken = async (token) => {
        if (!token) {
            jumpToLogin();
        }
        const verifier = CognitoJwtVerifier.create({
            userPoolId: cognitoConfig.UserPoolId,
            tokenUse: "access",
            clientId: cognitoConfig.ClientId,
        });

        try {
            // display user info
            const payload = await verifier.verify(token);

            return Promise.resolve(token);
        } catch (e) {
            return Promise.reject();
        }
    };

    return null;
};

export default useValidateToken;