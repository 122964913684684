import Icon from "@ant-design/icons";
import React from "react";
import { ReactComponent as TasksIcon } from "../images/menu-tasks.svg";
import { ReactComponent as FileIcon } from "../images/geojson-icon.svg";

export const navMenuConfig = {
    TASKS: {
        icon: <Icon component={TasksIcon} />,
    },
    FILE: {
        icon: <Icon component={FileIcon} />,
    },
};