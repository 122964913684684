import React, { useEffect, useState } from "react";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Modal, Upload } from "antd";
import { api } from "../../api/http.config";
import { fetch } from "../../utils";
import axios from "axios";
import PropTypes from "prop-types";
import gjv from "geojson-validation";

const { Dragger } = Upload;

const UploadFile = ({ callback }) => {
    const [visible, setVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    const handleRemove = () => {
        setFileList([]);
    };
    const beforeUpload = (file) => {
        let isGeoJson = file.name.endsWith(".geojson");
        if (!isGeoJson) {
            message.error(`Oops! ${file.name} is not a GeoJson file, please upload a GeoJson file.`);
            return false;
        }

        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
            const fileObj = JSON.parse(reader.result);
            if (!gjv.valid(fileObj) || !validateFeaturesName(fileObj)) {
                message.error(`Oops! ${file.name} is not in a correct GeoJSON format, please upload a valid GeoJson file.`);
                return false;
            } else {
                setFileList([file]);
                return false;
            }
        };
    };

    const validateFeaturesName = geoJson => {
        let hasName = true;
        if (geoJson.type === "Feature") {
            hasName = !!geoJson.properties.name;
        } else if (geoJson.type === "FeatureCollection") {
            geoJson.features.forEach(f => {
                if (!hasName) {
                    return;
                }

                hasName = validateFeaturesName(f);
            });
        }

        return hasName;
    };

    const handleUpload = () => {
        const filename = fileList[0].name;
        setUploading(true);
        const url = `${api.file}?filename=${filename}`;
        const config = { cancelToken: cancelTokenSource.token };

        fetch({ url, data: fileList[0], config, method: "post" })
            .then(() => {
                setFileList([]);
                callback && callback();
                message.success("Upload successfully.");
            })
            .catch(() => {
                message.error("Upload failed.");
            })
            .finally(() => {
                setUploading(false);
                setVisible(false);
            });
    };

    const footerNode = (
        <>
            <Button
                onClick={() => {
                    setVisible(false);
                    setFileList([]);
                }}
            >
                Cancel
            </Button>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
            >
                Upload
            </Button>
        </>
    );

    return (
        <>
            <Button
                style={{ fontSize: 12 }}
                size="small"
                icon={<UploadOutlined />}
                onClick={() => setVisible(true)}
            >
                Upload GeoJson
            </Button>
            <Modal
                closable={false}
                visible={visible}
                title="Upload New Path"
                footer={footerNode}
            >
                <Dragger
                    onRemove={handleRemove}
                    beforeUpload={beforeUpload}
                    accept=".geojson"
                    multiple={false}
                    style={{ maxHeight: 140 }}
                    fileList={fileList}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
            </Modal>
        </>
    );
};

export default UploadFile;

UploadFile.propTypes = {
    callback: PropTypes.func,
};