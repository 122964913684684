import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import groversSlice from "./features/groversSlice";
import fileSlice from "./features/fileSlice";
import menuSlice from "./features/menuSlice";
import tasksSlice from "./features/tasksSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        grovers: groversSlice,
        file: fileSlice,
        menu: menuSlice,
        tasks: tasksSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;