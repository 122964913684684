import area from "@turf/area";
import { polygon } from "@turf/helpers";

export const getDistance = latLngs => {
    if (latLngs.length <= 1) {
        return 0;
    }

    let dist = 0;
    for (let i = 1; i < latLngs.length; i++) {
        const latLngOrig = L.latLng(latLngs[i - 1]);
        const latLngDest = L.latLng(latLngs[i]);
        dist += latLngDest.distanceTo(latLngOrig);
    }

    return dist.toFixed(2);
};

// from [[xxx, xxx],[xxx, xxx]] to [{lat: xxx, lng: xxx}, {}]
export const toLatLngs = coordinates => {
    return coordinates.map(item => ({ lat: item[0], lng: item[1] }));
};

export const calcPolygonArea = coords => {
    return area(polygon(coords)).toFixed(2);
};