import React, { useEffect, useState } from "react";
import useSetPosition from "../../hooks/useSetPosition";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import TasksDetails from "../tasks/TasksDetails";
import TasksListTabs from "../tasks/TasksListTabs";
import { clearAllTasks, updateCurrentTask } from "../../store/features/tasksSlice";
import axios from "axios";
import { resetCurrentFile } from "../../store/features/fileSlice";
import { TASKS } from "../../utils/variables";
import { DRAWER_WIDTH } from "../../config/common.config";

const TasksDrawer = () => {
    const [activeTab, setActiveTab] = useState("current");

    const { selectedMenu } = useSelector(s => s.menu);
    const visible = selectedMenu === TASKS;
    const right = useSetPosition(visible);

    useEffect(() => {
        if (!visible) {
            dispatch(updateCurrentTask({}));
            dispatch(resetCurrentFile());
        }
    }, [visible]);

    const cancelTokenSource = axios.CancelToken.source();

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(updateSelectedMenu(null));
        dispatch(clearAllTasks());
        cancelTokenSource.cancel();
    };

    return (
        <Drawer
            className="tasks drawer-details"
            mask={false}
            style={{ right }}
            onClose={handleClose}
            title="TASKS"
            visible={visible}
            width={DRAWER_WIDTH}
        >
            <TasksDetails />
            <TasksListTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                visible={visible}
                cancelToken={cancelTokenSource.token}
            />
        </Drawer>
    );
};

export default TasksDrawer;
