import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { TASK_PANE_Z_INDEX } from "../../config/map.config";

const SetCurrentTaskZIndex = () => {
    const map = useMap();
    const { currentTasksPath } = useSelector(s => s.tasks);

    useEffect(() => {
        map.getPane("current-tasks-path").style.zIndex = TASK_PANE_Z_INDEX;
    }, [currentTasksPath]);

    return null;
};

export default SetCurrentTaskZIndex;