import React, { useState } from "react";
import { Button, List, Popconfirm, message } from "antd";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
    clearCurrentTasksPath,
    clearCurrentTasksPathTimer,
    getCurrentTasksList,
    getIncompleteTasks, getTasksPath,
    updateCurrentTask,
} from "../../store/features/tasksSlice";
import PropTypes from "prop-types";
import { taskApi } from "../../api/http.config";
import { fetch } from "../../utils";
import axios from "axios";
import { getFile, resetCurrentFile, updateCurrentFeatureName, updateCurrentFilename } from "../../store/features/fileSlice";

const { Item } = List;

const TasksList = props => {
    const { loading, data = [], showStopBtn = false } = props;
    const { currentTask } = useSelector(s => s.tasks);
    const { taskId } = currentTask;
    const dispatch = useDispatch();
    const cancelTokenSource = axios.CancelToken.source();

    const [stopTaskId, setStopTaskId] = useState(null);
    const [stopLoading, setStopLoading] = useState(false);

    const handleSelectTask = item => async () => {
        const { filename, featureName, taskId } = item;
        dispatch(updateCurrentTask(item));
        dispatch(updateCurrentFilename(filename));

        dispatch(clearCurrentTasksPathTimer());
        dispatch(clearCurrentTasksPath());

        dispatch(getTasksPath({ taskId, cancelToken: cancelTokenSource.token }));
        await dispatch(getFile({ filename, cancelToken: cancelTokenSource.token }));

        setTimeout(() => {
            dispatch(updateCurrentFeatureName(featureName));
        }, 200);
    };

    const handleClickStopTask = item => () => {
        const { taskId, groverId } = item;
        setStopLoading(true);
        setStopTaskId(taskId);

        const url = taskApi.triggerTask;
        const data = { taskId, groverId, action: "stop" };

        fetch({ url, data, method: "post" })
            .then(() => {
                message.success("Task has been stopped");
                dispatch(getCurrentTasksList(cancelTokenSource.token));
                dispatch(getIncompleteTasks(cancelTokenSource.token));

                dispatch(updateCurrentTask({}));
                dispatch(resetCurrentFile());
            })
            .catch(e => {
                message.error(e || "Error, please try later.");
            })
            .finally(() => {
                setStopLoading(false);
                setStopTaskId(null);
            });
    };

    const stopBtn = item => (
        <Popconfirm
            visible={stopTaskId === item.taskId}
            placement="left"
            title="Are you sure to stop this task?"
            onConfirm={handleClickStopTask(item)}
            onCancel={() => setStopTaskId(null)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                loading: stopLoading && stopTaskId === item.taskId,
            }}
            cancelButtonProps={{
                disabled: stopLoading && stopTaskId === item.taskId,
            }}
        >
            <Button
                className="tasks-stop-btn"
                onClick={() => setStopTaskId(item.taskId)}
                icon={<span className="stop-icon" />}
            >
                Stop Task
            </Button>
        </Popconfirm>
    );

    const itemDesc = item => {
        const { filename, startTime, taskId } = item;
        return (
            <>
                <div className="flex-space-between">
                    <span>{filename}</span>
                    <span>{startTime}</span>
                </div>
                {
                    showStopBtn && currentTask.taskId === taskId && stopBtn(item)

                }
            </>
        );
    };

    const renderItem = item => (
        <Item
            className={classnames({ "tasks-list-item-active": taskId === item.taskId })}
            style={{ padding: "4px 0" }}
        >
            <Item.Meta
                title={(
                    <Button
                        disabled={loading || stopLoading}
                        onClick={handleSelectTask(item)}
                        style={{ padding: "4px 0" }}
                        type="link"
                    >
                        {`${item.key + 1}. ${item.featureName}`}
                    </Button>

                )}
                description={itemDesc(item)}
            />
        </Item>
    );

    return (
        <List
            size="small"
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={renderItem}
            pagination={{
                position: "bottom",
                simple: true,
                total: data.length,
                pageSize: 10,
            }}
        />
    );
};

export default TasksList;

TasksList.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array,
    showStopBtn: PropTypes.bool,
};
