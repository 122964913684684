import React, { useEffect } from "react";
import { Pane, Polyline } from "react-leaflet";
import SetCurrentTaskZIndex from "./SetCurrentTaskZIndex";
import PropTypes from "prop-types";
import { getTaskById } from "../../utils/tasks";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { TASKS } from "../../utils/variables";
import axios from "axios";
import {
    clearCurrentTasksPath,
    clearCurrentTasksPathTimer,
    getTasksPath,
    updateCurrentTask,
} from "../../store/features/tasksSlice";
import { getFile, updateCurrentFeatureName, updateCurrentFilename } from "../../store/features/fileSlice";

const CurrentTasksPane = props => {
    const { currentTasksPath } = props;
    const { currentTasksList, currentTask } = useSelector(s => s.tasks);
    const dispatch = useDispatch();
    const cancelTokenSource = axios.CancelToken.source();

    const pathLineOptions = {
        color: "#59F400",
    };

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    const handleClickTask = taskId => async () => {
        const task = getTaskById(taskId, currentTasksList)[0];
        const { filename, featureName } = task;

        if (currentTask.taskId !== taskId) {
            dispatch(updateSelectedMenu(TASKS));

            dispatch(updateCurrentTask(task));
            dispatch(updateCurrentFilename(filename));

            dispatch(clearCurrentTasksPathTimer());
            dispatch(clearCurrentTasksPath());

            dispatch(getTasksPath({ taskId, cancelToken: cancelTokenSource.token }));
            await dispatch(getFile({ filename, cancelToken: cancelTokenSource.token }));

            setTimeout(() => {
                dispatch(updateCurrentFeatureName(featureName));
            }, 200);
        }
    };

    return (
        <>
            <Pane name="current-tasks-path">
                {
                    Object.entries(currentTasksPath).map(([id, path]) => {
                        if (!path.length) {
                            return;
                        }
                        return (
                            <Polyline
                                eventHandlers={{
                                    click: handleClickTask(id),
                                }}
                                key={id}
                                pathOptions={pathLineOptions}
                                positions={path}
                            />
                        );
                    })
                }
            </Pane>
            <SetCurrentTaskZIndex />
        </>
    );
};

export default CurrentTasksPane;

CurrentTasksPane.propTypes = {
    currentTasksPath: PropTypes.object.isRequired,
};