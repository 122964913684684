import React from "react";
import PropTypes from "prop-types";

const DividerBar = props => {
    const { name, extra, showBottomBorder = false, showTopBorder = false } = props;
    const borderStyle = "1px solid rgb(217, 217, 217)";

    const bottomBorderStyle = showBottomBorder ? {
        borderBottom: borderStyle,
    } : {};
    const topBorderStyle = showTopBorder ? {
        borderTop: borderStyle,
    } : {};

    return (
        <div className="divider-bar" style={{ ...bottomBorderStyle, ...topBorderStyle }}>
            <span>{name}</span>
            {extra}
        </div>
    );
};

export default DividerBar;

DividerBar.propTypes = {
    name: PropTypes.string.isRequired,
    extra: PropTypes.node,
    showBottomBorder: PropTypes.bool,
    showTopBorder: PropTypes.bool,
};