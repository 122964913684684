export const awsConfig = {
    region: "ap-southeast-2",
};

export const cognitoConfig = {
    //Grover_View_Users
    UserPoolId: "ap-southeast-2_8687G8iZB",
    ClientId: "6h6r0q482nrp52r7d85fbesre4",
};

