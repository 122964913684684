import React, { useEffect, useState } from "react";
import DividerBar from "../common/DividerBar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getFile, getFileList, updateCurrentFilename } from "../../store/features/fileSlice";
import { Button, List, message, Popconfirm } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { updateFollowId } from "../../store/features/groversSlice";
import UploadFile from "./UploadFile";
import PropTypes from "prop-types";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { api } from "../../api/http.config";
import { fetch } from "../../utils";
import { FEATURES } from "../../utils/variables";

const { Item } = List;

const FileList = props => {
    const { visible } = props;
    const [deleteFilename, setDeleteFilename] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const cancelTokenSource = axios.CancelToken.source();
    const dispatch = useDispatch();
    const { loading, list, downloadFileLoading, currentFilename } = useSelector(s => s.file);

    useEffect(() => {
        if (visible && !Object.keys(list).length) {
            dispatch(getFileList(cancelTokenSource.token));
        }
    }, [visible]);

    const itemDesc = (time, size) => (
        <>
            <span>{time}</span>
            <span style={{ marginLeft: 24 }}>{size}</span>
        </>
    );

    const handleDelete = filename => () => {
        if (!filename) {
            return;
        }
        const url = `${api.file}/${filename}`;
        setDeleteFilename(filename);
        setDeleteLoading(true);

        fetch({ url, method: "delete" })
            .then(() => {
                dispatch(getFileList(cancelTokenSource.token));
            })
            .catch(e => {
                message.error(e.message || "Error, please try it later.");
            })
            .finally(() => {
                setDeleteLoading(false);
                setDeleteFilename(null);
            });
    };

    const handleRenderPath = filename => async () => {
        if (filename !== currentFilename) {
            dispatch(updateFollowId(null));
            dispatch(updateCurrentFilename(filename));
            await dispatch(getFile({ filename, cancelToken: cancelTokenSource.token }));

            dispatch(updateSelectedMenu(FEATURES));
        }
    };

    const deleteBtn = item => (
        <Popconfirm
            visible={deleteFilename === item.filename}
            placement="left"
            title="Are you sure to delete this file?"
            onConfirm={handleDelete(item.filename)}
            onCancel={() => setDeleteFilename(null)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
                loading: deleteLoading && deleteFilename === item.filename,
            }}
            cancelButtonProps={{
                disabled: deleteLoading && deleteFilename === item.filename,
            }}
        >
            <Button
                key="del"
                type="link"
                onClick={() => setDeleteFilename(item.filename)}
            >
                <DeleteOutlined />
            </Button>
        </Popconfirm>
    );

    const renderItem = item => (
        <Item
            className={classnames({ "paths-list-item-active": currentFilename === item.filename })}
            style={{ padding: "4px 24px" }}
            // actions={[deleteBtn(item)]}
        >
            <Item.Meta
                title={(
                    <>
                        <Button
                            disabled={deleteLoading}
                            onClick={handleRenderPath(item.filename)}
                            style={{ padding: "4px 0" }}
                            type="link"
                        >
                            {`${item.idx + 1}. ${item.filename}`}
                        </Button>
                        {
                            downloadFileLoading && currentFilename === item.filename
                            && <LoadingOutlined style={{ marginLeft: 10 }} />
                        }
                    </>
                )}
                description={itemDesc(item.time, item.size)}
            />
        </Item>
    );

    const updateFileList = () => {
        dispatch(getFileList(cancelTokenSource.token));
    };

    return (
        <div className="file-list">
            <DividerBar name="GeoJSON LIST" extra={<UploadFile callback={updateFileList} />} />
            <List
                size="small"
                loading={loading}
                itemLayout="horizontal"
                dataSource={list && Object.values(list)}
                renderItem={renderItem}
                pagination={{
                    position: "bottom",
                    simple: true,
                    total: Object.keys(list).length,
                    pageSize: 10,
                }}
            />
        </div>
    );
};

export default FileList;

FileList.propTypes = {
    visible: PropTypes.bool.isRequired,
};