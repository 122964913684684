import React, { useEffect } from "react";
import { Drawer } from "antd";
import FileList from "../file/FileList";
import { useDispatch, useSelector } from "react-redux";
import useSetPosition from "../../hooks/useSetPosition";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { resetCurrentFile } from "../../store/features/fileSlice";
import { FILE } from "../../utils/variables";
import { DRAWER_WIDTH } from "../../config/common.config";

const FileDrawer = () => {
    const dispatch = useDispatch();

    const { selectedMenu } = useSelector(s => s.menu);
    const { currentFilename } = useSelector(s => s.file);
    const visible = selectedMenu === FILE;
    const right = useSetPosition(visible);

    useEffect(() => {
        if (visible && currentFilename) {
            dispatch(resetCurrentFile());
        }
    }, [visible]);

    const handleClose = () => {
        dispatch(updateSelectedMenu(null));
    };

    return (
        <Drawer
            className="file"
            mask={false}
            style={{ right }}
            onClose={handleClose}
            title="GeoJSON"
            visible={visible}
            width={DRAWER_WIDTH}
        >
            <FileList visible={visible} />
        </Drawer>
    );
};

export default FileDrawer;