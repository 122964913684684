import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

const SelectComp = props => {
    const { title, options, onSelect, value, loading } = props;

    return (
        <div style={{ marginBottom: 26 }}>
            <div className="title">{title}</div>
            <Select
                disabled={loading}
                loading={loading}
                style={{ width: "100%", marginTop: 12 }}
                value={value}
                onChange={onSelect}
            >
                {
                    options.map(item => {
                        return (
                            <Option key={item} value={item}>{item}</Option>
                        );
                    })
                }
            </Select>
        </div>
    );
};

export default SelectComp;

SelectComp.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string,
    loading: PropTypes.bool,
};