import axios from "../api/instance";
import dayjs from "dayjs";
import numeral from "numeral";

export function fetch ({ url, params = {}, data = {}, method = "get", config = {} }) {
    return axios({
        method,
        url,
        params,
        data,
        ...config,
    }).then(data => {
        if (data.status === 200) {
            return data.data;
        }
        return Promise.reject(data);
    }).catch(e => {
        return Promise.reject(e);
    });
}

export const removeXmlTag = str => str && str.replace(/<\/?[^>]+(>|$)/, "");

export const getTagInnerTxt = (str, tag = "h2") => {
    const re = new RegExp("[^><]+(?=<\/" + tag + ">)");
    return str.match(re)[0];
};

export const formatTime = (time, format = "DD/MM/YYYY HH:mm") => {
    return dayjs(time).format(format);
};

export const formatTimestamp = (time, format = "DD/MM/YYYY HH:mm") => {
    const daytime = time > 9999999999 ? dayjs(time) : dayjs.unix(time);
    return daytime.format(format);
};

export const formatNumber = (num, format = "0,0") => numeral(num).format(format);
