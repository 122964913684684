import React from "react";
import { Button, Descriptions } from "antd";
import PropTypes from "prop-types";
import { Popup } from "react-leaflet";

const { Item } = Descriptions;

const GroverPopupPane = props => {
    const { id, data, date, onClickFollow, isFollow } = props;
    const groverPopupPaneItems = ["Status", "Battery", "Speed", "Altitude"];

    return (
        <Popup autoPan={false}>
            <div className="popup-title">{`Grover: ${id}`}</div>
            <Descriptions
                column={2}
                size="small"
                layout="vertical"
            >
                {
                    groverPopupPaneItems.map(item => {
                        return (
                            <Item key={item} label={item}>{data[item.toLowerCase()] || "-"}</Item>
                        );
                    })
                }
            </Descriptions>
            <div className="popup-date">{`Last Update: ${date}`}</div>
            <Button className="popup-button" type="primary" size="small" onClick={onClickFollow(id)}>
                {isFollow ? "Cancel" : "Follow"}
            </Button>
        </Popup>
    );
};

export default GroverPopupPane;

GroverPopupPane.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
        status: PropTypes.string,
        battery: PropTypes.string,
        speed: PropTypes.string,
        altitude: PropTypes.string,
    }).isRequired,
    date: PropTypes.string.isRequired,
    onClickFollow: PropTypes.func.isRequired,
    isFollow: PropTypes.bool.isRequired,
};