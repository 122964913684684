import React, { useEffect } from "react";
import DividerBar from "../common/DividerBar";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TasksList from "./TasksList";
import { getCompleteTasks, getCurrentTasksList, getIncompleteTasks } from "../../store/features/tasksSlice";
import NewTaskBtn from "./NewTaskBtn";

const { TabPane } = Tabs;

const TasksListTabs = props => {
    const { cancelToken, visible, activeTab, setActiveTab } = props;
    const {
        currentTasksListLoading, completeTasksLoading, incompleteTasksLoading,
        currentTasksList, completeTasks, incompleteTasks,
    } = useSelector(s => s.tasks);
    const dispatch = useDispatch();

    const onChange = key => setActiveTab(key);

    useEffect(() => {
        if (!visible) {
            return setActiveTab("current");
        }

        if (activeTab === "current" && !currentTasksList.length) {
            dispatch(getCurrentTasksList(cancelToken));
        } else if (activeTab === "complete" && !completeTasks.length) {
            dispatch(getCompleteTasks(cancelToken));
        } else if (activeTab === "incomplete" && !incompleteTasks.length) {
            dispatch(getIncompleteTasks(cancelToken));
        }

    }, [visible, activeTab]);

    return (
        <div className="tasks-list">
            <DividerBar showTopBorder name="TASK LIST" extra={<NewTaskBtn />} />
            <Tabs disabled className="tasks-list-tabs" activeKey={activeTab} onChange={onChange}>
                <TabPane tab="Current" key="current">
                    <TasksList
                        loading={currentTasksListLoading}
                        data={currentTasksList}
                        showStopBtn
                    />
                </TabPane>
                <TabPane tab="Complete" key="complete">
                    <TasksList
                        loading={completeTasksLoading}
                        data={completeTasks}
                    />
                </TabPane>
                <TabPane tab="Incomplete" key="incomplete">
                    <TasksList
                        loading={incompleteTasksLoading}
                        data={incompleteTasks}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default TasksListTabs;