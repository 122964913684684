import React, { useEffect, useState } from "react";
import { Collapse, Descriptions } from "antd";
import { useSelector } from "react-redux";
import { calcPolygonArea, getDistance, toLatLngs } from "../../utils/map";

const { Panel } = Collapse;
const { Item } = Descriptions;

const FeaturesDetails = () => {
    const [activeKey, setActiveKey] = useState([]);

    const { currentFeatureName, pathNames, polygonNames, featuresMap } = useSelector(s => s.file);

    const getDescItems = () => {
        if (pathNames.includes(currentFeatureName)) {
            return ["Distance"];
        } else if (polygonNames.includes(currentFeatureName)) {
            return ["Area"];
        } else {
            return ["Latitude", "Longitude"];
        }
    };

    useEffect(() => {
        if (currentFeatureName) {
            setActiveKey(["features-details"]);
        }
    }, [currentFeatureName]);

    const handleChangeCollapse = key => setActiveKey(key);

    const getItemValue = item => {
        const feature = featuresMap[currentFeatureName];

        if (!feature) {
            return "-";
        }

        const { coordinates } = feature.geometry;
        switch (item) {
            case "Distance":
                const dist = getDistance(toLatLngs(coordinates));
                return dist ? dist + " m" : "-";
            case "Latitude":
                return coordinates[0]?.toFixed(6) || "-";
            case "Longitude":
                return coordinates[1]?.toFixed(6) || "-";
            case "Area":
                const area = calcPolygonArea(coordinates);
                return area ? area + " sqm" : "-";
            case "Shape":
                return feature.geometry?.type || "-";
            default:
                return "-";
        }
    };

    return (
        <Collapse
            className="drawer-details"
            onChange={handleChangeCollapse}
            expandIconPosition="end"
            activeKey={activeKey}
            bordered={false}
        >
            <Panel header="DETAILS" key="features-details">
                <Descriptions
                    className="features-details"
                    title={currentFeatureName || "-"}
                    column={2}
                    layout="vertical"
                    size="small"
                    labelStyle={{ marginTop: 14 }}
                >
                    {
                        getDescItems().map(item => {
                            return (
                                <Item label={item} key={item}>{getItemValue(item)}</Item>
                            );
                        })
                    }
                </Descriptions>
            </Panel>
        </Collapse>
    );
};

export default FeaturesDetails;