import axios from "axios";
import { jumpToLogin } from "../utils/login";

let store;

export const injectStore = _store => {
    store = _store;
};

const instance = axios.create({
    headers: {
        "content-type": "application/json",
    },
    timeout: 10000,
    validateStatus (status) {
        return status >= 200 && status <= 500;
    },
});

instance.interceptors.request.use(config => {
    const state = store.getState();
    const jwt = state.auth.token || "";
    if (jwt) {
        config.headers["Authorization"] = "Bearer " + jwt;
    }

    return config;
});

instance.interceptors.response.use(res => {
    try {
        switch (res.status) {
            case 401:
                jumpToLogin();
                return Promise.reject({ message: "UNAUTHORIZED" });
            case 500:
                return Promise.reject(res);
            default:
                return res;
        }

    } catch (e) {
        return Promise.reject(e);
    }
});

export default instance;