import React from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useSetPosition from "../../hooks/useSetPosition";
import { LeftOutlined } from "@ant-design/icons";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import FeaturesDetails from "../features/FeaturesDetails";
import FeaturesList from "../features/FeaturesList";
import { resetCurrentFile } from "../../store/features/fileSlice";
import { FEATURES, FILE } from "../../utils/variables";
import { DRAWER_WIDTH } from "../../config/common.config";

const FeaturesDrawer = () => {
    const { currentFilename } = useSelector(s => s.file);
    const { selectedMenu } = useSelector(s => s.menu);
    const visible = selectedMenu === FEATURES;
    const right = useSetPosition(visible);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(updateSelectedMenu(FILE));
        dispatch(resetCurrentFile());
    };

    return (
        <Drawer
            className="features"
            mask={false}
            style={{ right }}
            closeIcon={<LeftOutlined />}
            onClose={handleClose}
            title={currentFilename}
            visible={visible}
            width={DRAWER_WIDTH}
        >
            <FeaturesDetails />
            <FeaturesList />
        </Drawer>
    );
};

export default FeaturesDrawer;