const apiBaseUrl = "https://api.roverview.co.nz";

export const api = {
    file: apiBaseUrl + "/file",
};

export const groverApi = {
    list: "https://8qh05luuhb.execute-api.ap-southeast-2.amazonaws.com/prod/grovers",
};

export const taskApi = {
    triggerTask: "https://jeq5v7kqn5.execute-api.ap-southeast-2.amazonaws.com/prod/task",
    task: "https://h0j102zua9.execute-api.ap-southeast-2.amazonaws.com/prod/task",
    taskDetails: "https://xq6m8tvc2l.execute-api.ap-southeast-2.amazonaws.com/prod/task-details",
};

export const loginApi = {
    url: "https://grover-view.auth.ap-southeast-2.amazoncognito.com",
};

