import React, { useEffect, useState } from "react";
import { Collapse, Descriptions } from "antd";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Item } = Descriptions;

const TasksDetails = () => {
    const [activeKey, setActiveKey] = useState([]);

    const { currentTask, currentTasksList, incompleteTasks } = useSelector(s => s.tasks);

    useEffect(() => {
        setActiveKey(Object.keys(currentTask).length ? ["tasks-details"] : []);
    }, [currentTask]);

    const handleChangeCollapse = key => setActiveKey(key);

    return (
        <Collapse
            className="drawer-details"
            onChange={handleChangeCollapse}
            expandIconPosition="end"
            activeKey={activeKey}
            bordered={false}
        >
            <Panel header="DETAILS" key="tasks-details">
                <Descriptions
                    className="tasks-details"
                    title={currentTask.featureName}
                    column={2}
                    layout="vertical"
                    size="small"
                    labelStyle={{ marginTop: 14 }}
                >
                    <Item label="GeoJSON">
                        {currentTask.filename?.replace(".geojson", "") || "-"}
                    </Item>
                    <Item label="Robot">
                        {currentTask.groverId || "-"}
                    </Item>
                    <Item label="Start Time">
                        {currentTask.startTime || "-"}
                    </Item>
                    {
                        !currentTasksList.includes(currentTask) && (
                            <Item label="Finish Time">
                                {
                                    currentTask.updatedAt || "-"
                                }
                            </Item>
                        )
                    }
                    {
                        incompleteTasks.includes(currentTask) && (
                            <Item label="Error">
                                {
                                    `${+currentTask.errorCode ? currentTask.errorCode + ":" : ""} ${currentTask.errorMsg || (currentTask.status === "Stopped" ? "Manually Stopped" : "")}`
                                }
                            </Item>
                        )
                    }
                </Descriptions>
            </Panel>
        </Collapse>
    );
};

export default TasksDetails;