import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateToken (state, action) {
            state.token = action.payload;
        },
    },
});

export const { updateToken } = authSlice.actions;
export default authSlice.reducer;
