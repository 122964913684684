import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/http.config";
import { fetch, formatTime } from "../../utils";

const initialState = {
    list: {},
    loading: false,
    downloadFileLoading: false,
    pathNames: [],
    waypointNames: [],
    polygonNames: [],
    currentFeatureName: null,
    currentFilename: null,
    featuresMap: {},
    geoJsonFile: null,
};

export const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        updateCurrentFilename (state, action) {
            state.currentFilename = action.payload;
        },
        clearFileList (state) {
            state.list = {};
        },
        resetCurrentFile (state) {
            state.currentFilename = null;
            state.geoJsonFile = null;
            state.featuresMap = {};
            state.currentFeatureName = null;
            state.pathNames = [];
            state.waypointNames = [];
            state.polygonNames = [];
        },
        updateCurrentFeatureName (state, action) {
            state.currentFeatureName = action.payload;
        },
    },
    extraReducers (builder) {
        builder
            .addCase(getFileList.pending, (state) => {
                state.loading = true;
                state.list = {};
            })
            .addCase(getFileList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addCase(getFileList.rejected, (state) => {
                state.loading = false;
                state.list = {};
            })
            .addCase(getFile.pending, (state) => {
                state.downloadFileLoading = true;
            })
            .addCase(getFile.fulfilled, (state, action) => {
                state.downloadFileLoading = false;
                state.featuresMap = action.payload.featuresMap;
                state.pathNames = action.payload.pathNames;
                state.waypointNames = action.payload.waypointNames;
                state.polygonNames = action.payload.polygonNames;
                state.geoJsonFile = action.payload.geoJsonFile;
            })
            .addCase(getFile.rejected, (state) => {
                state.downloadFileLoading = false;
            });
    },
});

export const getFileList = createAsyncThunk("getFileList", async (cancelToken) => {
    try {
        const url = api.file;
        const config = { cancelToken };
        const res = await fetch({ url, config });

        return formatData(res);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

export const getFile = createAsyncThunk("getFile", async ({ filename, cancelToken }) => {
    try {
        const url = `${api.file}/${filename}`;
        const config = { cancelToken };

        const geoJson = await fetch({ url, config });

        return formatGeoJson(geoJson);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

const formatData = res => {
    const data = {};
    res.forEach((item, idx) => {
        const fullPath = item.filename;
        const pathArr = fullPath.split("/");
        const filename = pathArr[pathArr.length - 1];
        data[filename] = {
            filename,
            key: fullPath,
            idx,
            size: (+item.size / 1000).toFixed(2) + "KB",
            time: formatTime(item.time),
        };
    });
    return data;
};

const formatGeoJson = geoJsonFile => {
    const featuresMap = {};
    const pathNames = [];
    const waypointNames = [];
    const polygonNames = [];

    geoJsonFile.features?.length && geoJsonFile.features.forEach(item => {
        const name = item.properties.name;
        featuresMap[name] = item;
        switch (item.geometry.type) {
            case "Point":
                waypointNames.push(name);
                break;
            case "LineString":
                pathNames.push(name);
                break;
            case "Polygon":
                polygonNames.push(name);
                break;
            default:
                break;
        }
    });

    return {
        featuresMap,
        pathNames,
        waypointNames,
        polygonNames,
        geoJsonFile,
    };
};

export const {
    updateCurrentFilename, updateCurrentFeatureName,
    clearFileList, resetCurrentFile,
} = fileSlice.actions;
export default fileSlice.reducer;
