import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "../css/leaflet.scss";
import axios from "axios";
import { mapConfig } from "../config/map.config";
import ChangeMapCenter from "./map/ChangeMapCenter";
import { getGrovers, updateFollowId } from "../store/features/groversSlice";
import { useSelector, useDispatch } from "react-redux";
import { activeGroverIcon, inactiveGroverIcon } from "../utils/markers";
import {
    clearCurrentTasksPath,
    clearCurrentTasksPathTimer,
    getCurrentTasksList,
    getTasksPath,
    setCurrentTasksPathTimer,
} from "../store/features/tasksSlice";
import GroverPopupPane from "./map/GroverPopupPane";
import "leaflet-rotatedmarker";
import CurrentTasksPane from "./map/CurrentTasksPane";
import DisplayGeoJson from "./map/DisplayGeoJson";

const Map = () => {
    const [center, setCenter] = useState(mapConfig.petoneCoordinates);
    const cancelTokenSource = axios.CancelToken.source();

    const dispatch = useDispatch();
    const { currentTasksList, currentTasksPath } = useSelector(s => s.tasks);
    const { coordinates, groversStatus, followId } = useSelector(s => s.grovers);
    const { selectedMenu } = useSelector(s => s.menu);

    useEffect(() => {
        setTimeout(() => {
            getGroversData();
        }, 700);

        const timer = setInterval(getGroversData, mapConfig.refreshFrequency);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (!selectedMenu) {
            setTimeout(() => {
                dispatch(getCurrentTasksList(cancelTokenSource.token));
            }, 700);
        } else if (selectedMenu !== "TASKS") {
            dispatch(clearCurrentTasksPathTimer());
            dispatch(clearCurrentTasksPath());
        }
    }, [selectedMenu]);

    useEffect(() => {
        setTimeout(() => {
            getCurrentTasksPath();
        }, 700);

        setCurrentTasksPathInterval();
        return () => {
            dispatch(clearCurrentTasksPathTimer());
        };
    }, [currentTasksList]);

    useEffect(() => {
        if (followId) {
            setCenter(coordinates[followId]);
        }
    }, [coordinates, followId]);

    const setCurrentTasksPathInterval = () => {
        dispatch(setCurrentTasksPathTimer(setInterval(getCurrentTasksPath, mapConfig.refreshFrequency)));
    };

    const getCurrentTasksPath = () => {
        if (currentTasksList.length) {
            const taskIds = currentTasksList.map(item => item.taskId);
            dispatch(getTasksPath({ taskId: taskIds.join(","), cancelToken: cancelTokenSource.token }));
        }
    };

    const getGroversData = () => {
        try {
            dispatch(getGrovers(cancelTokenSource.token));
        } catch (e) {
            console.log(e);
        }
    };

    const handleFollow = id => () => {
        if (id !== followId) {
            dispatch(updateFollowId(id));
            setCenter(coordinates[id]);
        } else {
            dispatch(updateFollowId(null));
        }
    };

    return (
        <MapContainer
            center={mapConfig.petoneCoordinates}
            zoom={mapConfig.zoom}
            maxZoom={mapConfig.maxZoom}
        >
            <ChangeMapCenter center={center} follow={!!followId} />
            <TileLayer
                maxZoom={mapConfig.maxZoom}
                url={`https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png?api=${mapConfig.linzKey}`}
            />
            {
                Object.entries(coordinates).map(([id, coordinates]) => {
                    return (
                        <Marker
                            key={id}
                            icon={groversStatus[id]?.isActive ? activeGroverIcon : inactiveGroverIcon}
                            position={coordinates}
                            // rotationAngle={90} // todo: direction
                        >
                            <GroverPopupPane
                                id={id}
                                onClickFollow={handleFollow}
                                date={groversStatus[id].time}
                                isFollow={followId === id}
                                data={groversStatus[id]}
                            />
                        </Marker>
                    );
                })
            }
            <DisplayGeoJson />
            {
                !!Object.keys(currentTasksPath).length && (<CurrentTasksPane currentTasksPath={currentTasksPath} />)
            }
        </MapContainer>
    );
};

export default Map;
