import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GeoJSON, useMap } from "react-leaflet";
import { updateCurrentFeatureName } from "../../store/features/fileSlice";
import L from "leaflet";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";
import { endMarker } from "../../utils/markers";
import { GEOMETRY_TYPES } from "../../utils/variables";

const DisplayGeoJson = () => {
    const dispatch = useDispatch();
    const {
        currentFeatureName, featuresMap, geoJsonFile, pathNames,
    } = useSelector(s => s.file);

    const [features, setFeatures] = useState(null); // features displayed on map

    const map = useMap();
    const boundsOption = { padding: [150, 150] };

    useEffect(() => {
        if (!Object.keys(featuresMap).length) {
            setFeatures(null);
            return;
        }

        if (!currentFeatureName) {
            setFeatures(Object.values(featuresMap));
        } else if (pathNames.includes(currentFeatureName)) {

            const feature = featuresMap[currentFeatureName];
            const { coordinates } = feature.geometry;
            const start = buildPointFeature(coordinates[0], "pathStart");
            const end = buildPointFeature(coordinates[coordinates.length - 1], "pathEnd");
            setFeatures([feature, start, end]);

        } else {
            setFeatures(featuresMap[currentFeatureName]);
        }
        map.fitBounds(getBounds(geoJsonFile), boundsOption);

    }, [currentFeatureName, featuresMap]);

    const buildPointFeature = (coordinates, name) => ({
        type: "Feature",
        properties: {
            name,
            type: "path_start_end_marker",
        },
        geometry: {
            type: GEOMETRY_TYPES.POINT,
            coordinates,
        },
    });

    const getBounds = geoJson => {
        return L.geoJson(geoJson).getBounds();
    };

    const handleClickFeature = e => {
        if (!currentFeatureName) {
            const { feature } = e.propagatedFrom;
            dispatch(updateCurrentFeatureName(feature.properties.name));
        }
    };

    const pointToLayer = (feature, latlng) => {
        if (feature.properties.type !== "path_start_end_marker") {
            return L.marker(latlng);
        }
        // todo: change start/end markers
        const { name } = feature.properties;
        let icon;
        if (name === "pathStart") {
            icon = endMarker;
        } else if (name === "pathEnd") {
            icon = endMarker;
        }

        return L.marker(latlng, { icon });
    };

    return (
        <GeoJSON
            key={JSON.stringify(features)}
            data={features}
            eventHandlers={{
                click: handleClickFeature,
            }}
            pointToLayer={pointToLayer}
        />
    );
};

export default DisplayGeoJson;
