import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { NEW_TASK } from "../../utils/variables";

const NewTaskBtn = () => {
    const dispatch = useDispatch();

    const handleClickBtn = () => {
        dispatch(updateSelectedMenu(NEW_TASK));
    };

    return (
        <Button onClick={handleClickBtn} type="link">
            <PlusOutlined style={{ fontSize: 16, fontWeight: 800 }} />
        </Button>
    );
};

export default NewTaskBtn;