import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSetPosition from "../../hooks/useSetPosition";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { Button, Drawer, message } from "antd";
import {
    getFile,
    getFileList,
    resetCurrentFile,
    updateCurrentFeatureName,
    updateCurrentFilename,
} from "../../store/features/fileSlice";
import SelectComp from "../common/SelectComp";
import axios from "axios";
import { taskApi } from "../../api/http.config";
import { fetch } from "../../utils";
import { getCurrentTasksList, setNewTaskId } from "../../store/features/tasksSlice";
import { NEW_TASK, TASKS } from "../../utils/variables";
import { DRAWER_WIDTH } from "../../config/common.config";

const NewTaskDrawer = () => {
    const { selectedMenu } = useSelector(s => s.menu);
    const {
        list, pathNames, waypointNames, loading,
        currentFeatureName, currentFilename, downloadFileLoading,
    } = useSelector(s => s.file);
    const { data: groversData } = useSelector(s => s.grovers);

    const dispatch = useDispatch();
    const [grover, setGrover] = useState(null);
    const [startTaskLoading, setStartTaskLoading] = useState(false);

    const cancelTokenSource = axios.CancelToken.source();
    const visible = selectedMenu === NEW_TASK;
    const right = useSetPosition(visible);

    useEffect(() => {
        if (visible && !list.length) {
            dispatch(getFileList(cancelTokenSource.token));
        }
    }, [visible]);

    useEffect(() => {
        if (!visible) {
            reset();
        }
    }, [visible]);

    useEffect(() => {
        return () => cancelTokenSource.cancel();
    }, []);

    const handleClose = () => {
        dispatch(updateSelectedMenu(null));
        reset();
    };

    const reset = () => {
        setGrover(null);
    };

    const handleSelectFile = async val => {
        await dispatch(resetCurrentFile());
        dispatch(updateCurrentFilename(val));
        dispatch(updateCurrentFeatureName(null));
        await dispatch(getFile({ filename: val, cancelToken: cancelTokenSource.token }));
    };

    const handleSelectFeature = val => {
        dispatch(updateCurrentFeatureName(val));
    };

    const handleStart = () => {
        setStartTaskLoading(true);
        const url = taskApi.triggerTask;
        const method = "post";
        const config = { cancelToken: cancelTokenSource.token };

        const data = {
            action: "start",
            groverId: grover,
            filename: currentFilename,
            featureName: currentFeatureName,
            featureType: waypointNames.includes(currentFeatureName) ? "waypoint" : "path",
        };

        fetch({ url, method, data, config })
            .then(res => {
                dispatch(setNewTaskId(res));
                dispatch(updateSelectedMenu(TASKS));
                dispatch(getCurrentTasksList());
                setGrover(null);
            })
            .catch(e => {
                message.error(e || "Error, please try later.");
            })
            .finally(() => {
                setStartTaskLoading(false);
            });
    };

    return (
        <Drawer
            className="new-task"
            mask={false}
            style={{ right }}
            onClose={handleClose}
            title="NEW TASK"
            visible={visible}
            width={DRAWER_WIDTH}
        >
            <SelectComp
                loading={downloadFileLoading || loading}
                title="SELECT A FILE"
                options={Object.keys(list)}
                value={currentFilename}
                onSelect={handleSelectFile}
            />
            <SelectComp
                loading={downloadFileLoading}
                title="SELECT A FEATURE"
                options={[...pathNames, ...waypointNames]}
                value={currentFeatureName}
                onSelect={handleSelectFeature}
            />
            <SelectComp
                title="SELECT A GROVER"
                options={Object.keys(groversData)}
                value={grover}
                onSelect={v => setGrover(v)}
            />
            <Button
                className="new-task-start-btn"
                onClick={handleStart}
                disabled={!grover || !currentFilename || !currentFeatureName}
                loading={startTaskLoading}
            >
                Start
            </Button>
            <Button className="new-task-cancel-btn" onClick={handleClose} disabled={startTaskLoading}>Cancel</Button>
        </Drawer>
    );
};

export default NewTaskDrawer;