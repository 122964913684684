import { loginApi } from "../api/http.config";
import { cognitoConfig } from "../config/aws.config";

export const getTokenFromUrl = () => {
    const hash = window.location.hash;
    const params = hash.substr(1).split("&");

    const res = {};
    params.forEach(item => {
        const arr = item.split("=");
        res[arr[0]] = arr[1];
    });

    return res;
};

export const jumpToLogin = () => {
    window.location.href = `${loginApi.url}/login?response_type=token&client_id=${cognitoConfig.ClientId}&redirect_uri=https://roverview.co.nz/`;
};