import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { groverApi } from "../../api/http.config";
import { fetch, formatNumber, formatTimestamp } from "../../utils";
import dayjs from "dayjs";

const initialState = {
    data: {},
    coordinates: {},
    groversStatus: {},
    loading: false,
    followId: null,
};

export const groversSlice = createSlice({
    name: "grovers",
    initialState,
    reducers: {
        updateFollowId (state, action) {
            state.followId = action.payload;
        },
    },
    extraReducers (builder) {
        builder
            .addCase(getGrovers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGrovers.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                state.coordinates = action.payload.coordinates;
                state.groversStatus = action.payload.groversStatus;
            })
            .addCase(getGrovers.rejected, (state, action) => {
                state.loading = false;
                console.log(action.error);
            });
    },
});

export const getGrovers = createAsyncThunk("getGrovers", async (cancelToken) => {
    try {
        const url = groverApi.list;
        const config = { cancelToken };
        const res = await fetch({ url, config });

        return formatData(res);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }

});

const formatData = res => {
    const coordinates = {};
    const groversStatus = {};
    Object.entries(res).forEach(([id, item]) => {
        coordinates[id] = [item.latitude, item.longitude];

        const battery = item.battery && Object.keys(item.battery).length ? Math.max(...Object.values(item.battery)) : 0;
        const daytime = item.timestamp > 9999999999 ? dayjs(item.timestamp) : dayjs.unix(item.timestamp);
        const isActive = daytime.isAfter(dayjs().subtract(1, "minute"));
        groversStatus[id] = {
            isActive,
            time: formatTimestamp(item.timestamp),
            battery: battery ? battery + "%" : null,
            altitude: item.altitude ? formatNumber(item.altitude) + "m" : null,
            status: isActive ? item.status || null : "Inactive",
            speed: isActive ? item.speed + "m/s" : null,
        };
    });

    return { data: res, coordinates, groversStatus };
};

export const { updateFollowId } = groversSlice.actions;
export default groversSlice.reducer;
