import React, { useEffect, useState } from "react";
import { MENU_WIDTH } from "../config/common.config";

const useSetPosition = visible => {
    const [right, setRight] = useState(0);

    useEffect(() => {
        if (visible) {
            setRight(MENU_WIDTH);
        } else {
            setRight(0);
        }
    }, [visible]);

    return right;
};

export default useSetPosition;