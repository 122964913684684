import React from "react";
import { Menu } from "antd";
import { navMenuConfig } from "../../config/nav";
import FileDrawer from "./FileDrawer";
import TasksDrawer from "./TasksDrawer";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import FeaturesDrawer from "./FeaturesDrawer";
import NewTaskDrawer from "./NewTaskDrawer";
import { FEATURES, FILE } from "../../utils/variables";

const NavMenu = () => {
    const { selectedMenu } = useSelector(s => s.menu);
    const dispatch = useDispatch();

    const items = Object.entries(navMenuConfig).map(([key, item]) => {
        return {
            key,
            icon: item.icon,
            label: key === FILE ? "GeoJSON" : key,
        };
    });

    const handleSelect = ({ key }) => {
        dispatch(updateSelectedMenu(key));
    };

    const selectedKey = selectedMenu === FEATURES ? FILE : selectedMenu;

    return (
        <>
            <Menu
                selectedKeys={[selectedKey]}
                onSelect={handleSelect}
                theme="dark"
                mode="inline"
                items={items}
            />
            <FileDrawer />
            <TasksDrawer />
            <FeaturesDrawer />
            <NewTaskDrawer />
        </>
    );
};

export default NavMenu;