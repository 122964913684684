import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedMenu: null,
};

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        updateSelectedMenu (state, action) {
            state.selectedMenu = action.payload;
        },
    },
});

export const { updateSelectedMenu } = menuSlice.actions;
export default menuSlice.reducer;