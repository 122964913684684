import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { taskApi } from "../../api/http.config";
import { fetch, formatTime } from "../../utils";
import dayjs from "dayjs";

const initialState = {
    currentTasksListLoading: false,
    completeTasksLoading: false,
    incompleteTasksLoading: false,
    currentTasksPathLoading: false,
    currentTasksList: [],
    completeTasks: [],
    incompleteTasks: [],
    currentTask: {},
    currentTasksPath: {},
    currentTasksPathTimer: null,
    newTaskId: "",
};

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        updateCurrentTask (state, action) {
            state.currentTask = action.payload;
        },
        clearAllTasks (state) {
            state.currentTasksList = [];
            state.completeTasks = [];
            state.incompleteTasks = [];
        },
        setNewTaskId (state, action) {
            state.newTaskId = action.payload;
        },
        setCurrentTasksPathTimer (state, action) {
            state.currentTasksPathTimer = action.payload;
        },
        clearCurrentTasksPathTimer (state) {
            if (state.currentTasksPathTimer) {
                clearInterval(state.currentTasksPathTimer);
                state.currentTasksPathTimer = null;
            }
        },
        clearCurrentTasksPath (state) {
            state.currentTasksPath = {};
        },
    },
    extraReducers (builder) {
        builder
            .addCase(getCurrentTasksList.pending, (state) => {
                state.currentTasksListLoading = true;
                state.currentTasksList = [];
            })
            .addCase(getCurrentTasksList.fulfilled, (state, action) => {
                state.currentTasksListLoading = false;
                state.currentTasksList = action.payload;
                if (!!state.newTaskId) {
                    state.currentTask = action.payload.filter(item => item.taskId === state.newTaskId)[0];
                    state.newTaskId = "";
                }
            })
            .addCase(getCurrentTasksList.rejected, (state) => {
                state.currentTasksListLoading = false;
                state.currentTasksList = [];
            })
            .addCase(getCompleteTasks.pending, (state) => {
                state.completeTasksLoading = true;
                state.completeTasks = [];
            })
            .addCase(getCompleteTasks.fulfilled, (state, action) => {
                state.completeTasksLoading = false;
                state.completeTasks = action.payload;
            })
            .addCase(getCompleteTasks.rejected, (state) => {
                state.completeTasksLoading = false;
                state.completeTasks = [];
            })
            .addCase(getIncompleteTasks.pending, (state) => {
                state.incompleteTasksLoading = true;
                state.incompleteTasks = [];
            })
            .addCase(getIncompleteTasks.fulfilled, (state, action) => {
                state.incompleteTasksLoading = false;
                state.incompleteTasks = action.payload;
            })
            .addCase(getIncompleteTasks.rejected, (state) => {
                state.incompleteTasksLoading = false;
                state.incompleteTasks = [];
            })
            .addCase(getTasksPath.pending, (state) => {
                state.currentTasksPathLoading = true;
            })
            .addCase(getTasksPath.fulfilled, (state, action) => {
                state.currentTasksPathLoading = false;
                state.currentTasksPath = action.payload;
            })
            .addCase(getTasksPath.rejected, (state) => {
                state.currentTasksPathLoading = false;
            });
    },
});

export const getCurrentTasksList = createAsyncThunk("getCurrentTasksList", async (cancelToken) => {
    try {
        const url = taskApi.task;
        const params = { type: "current" };
        const config = { cancelToken };
        const res = await fetch({ url, params, config });

        return formatTasksList(res);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

export const getTasksPath = createAsyncThunk("getTasksPath", async ({ taskId, cancelToken }) => {
    try {
        const url = taskApi.taskDetails;
        const params = { taskId };
        const config = { cancelToken };
        const res = await fetch({ url, params, config });

        return formatTasksPath(res);
    } catch (e) {
        console.log(e);
    }
});

export const getCompleteTasks = createAsyncThunk("getCompleteTasks", async (cancelToken) => {
    try {
        const url = taskApi.task;
        const params = { type: "complete" };
        const config = { cancelToken };
        const res = await fetch({ url, params, config });

        return formatTasksList(res);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

export const getIncompleteTasks = createAsyncThunk("getIncompleteTasks", async (cancelToken) => {
    try {
        const url = taskApi.task;
        const params = { type: "incomplete" };
        const config = { cancelToken };
        const res = await fetch({ url, params, config });

        return formatTasksList(res);
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

const formatTasksList = res => res.sort((a, b) => dayjs(a.startTime).isBefore(b.startTime) ? 1 : -1).map((item, key) => ({
    ...item,
    updatedAt: formatTime(item.updatedAt),
    startTime: formatTime(item.startTime),
    key,
}));

const formatTasksPath = data => {
    const res = {};
    Object.entries(data).forEach(([id, path]) => {
        res[id] = path.sort((a, b) => +(a.sequence || 0) - +(b.sequence || 0))
            .map(item => ([item.latitude, item.longitude]));
    });

    return res;
};

export const {
    updateCurrentTask, clearAllTasks, setCurrentTasksPathTimer,
    clearCurrentTasksPathTimer, clearCurrentTasksPath, setNewTaskId,
} = tasksSlice.actions;

export default tasksSlice.reducer;

