import React from "react";
import Map from "./components/Map";
import useValidateToken from "./hooks/useValidateToken";
import NavMenu from "./components/menus/NavMenu";
import { Layout } from "antd";
import { ReactComponent as Logo } from "./images/logo.svg";
import Footer from "./components/Footer";

const { Sider, Content } = Layout;

function App () {

    const token = useValidateToken();

    return (
        <Layout>
            <Layout>
                <Layout>
                    <Content>
                        <Map />
                    </Content>
                </Layout>
                <Sider className="menu" trigger={null} collapsed>
                    <NavMenu />
                    <div className="logo-wrapper">
                        <Logo className="logo" />
                    </div>
                </Sider>
            </Layout>
            <Footer>©2022 Peek Robotics. All rights reserved.</Footer>
        </Layout>
    );
}

export default App;