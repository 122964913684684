import React, { useEffect, useState } from "react";
import { Button, List, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentFeatureName } from "../../store/features/fileSlice";
import DividerBar from "../common/DividerBar";
import classnames from "classnames";
import Icon, { SwapRightOutlined } from "@ant-design/icons";
import { ReactComponent as PathSvg } from "../../images/path-icon.svg";
import { ReactComponent as WaypointSvg } from "../../images/point.svg";
import { ReactComponent as PolygonSvg } from "../../images/polygon.svg";
import { updateSelectedMenu } from "../../store/features/menuSlice";
import { FEATURES, NEW_TASK } from "../../utils/variables";

const { Item } = List;

const FeaturesList = () => {
    const [value, setValue] = useState("all");
    const dispatch = useDispatch();
    const { pathNames, waypointNames, currentFeatureName, polygonNames } = useSelector(s => s.file);
    const { selectedMenu } = useSelector(s => s.menu);

    const allFeatures = [...pathNames, ...waypointNames, ...polygonNames];

    useEffect(() => {
        if (selectedMenu !== FEATURES) {
            setValue("all");
        }
    }, [selectedMenu]);

    const handleChangeValue = e => {
        setValue(e.target.value);
    };

    const handleReset = () => {
        setValue("all");
        if (currentFeatureName) {
            dispatch(updateCurrentFeatureName(null));
        }
    };

    const filterData = () => {
        switch (value) {
            case "paths":
                return pathNames;
            case "waypoints":
                return waypointNames;
            case "polygons":
                return polygonNames;
            case "all":
            default:
                return allFeatures;
        }
    };

    const handleSelectFeature = name => () => {
        dispatch(updateCurrentFeatureName(name));
    };

    const handleClickNewTask = () => {
        dispatch(updateSelectedMenu(NEW_TASK));
    };

    const newTaskBtn = (
        <Button
            onClick={handleClickNewTask}
            size="small"
            className="feature-list-new-task-btn"
            icon={<SwapRightOutlined />}
        >
            New Task
        </Button>
    );

    const getIconComponent = item => {
        if (pathNames.includes(item)) {
            return PathSvg;
        } else if (waypointNames.includes(item)) {
            return WaypointSvg;
        } else if (polygonNames.includes(item)) {
            return PolygonSvg;
        } else {
            return PathSvg;
        }

    };

    const renderItem = (item, i) => {
        const isSelected = currentFeatureName === item;
        return (
            <Item
                className={classnames({ "list-item-active": isSelected })}
                actions={isSelected && !polygonNames.includes(item) && [newTaskBtn]}
            >
                <Item.Meta
                    title={(
                        <Button
                            type="link"
                            onClick={handleSelectFeature(item)}
                        >
                            <Icon
                                style={{ marginRight: 8 }}
                                className={classnames({ "white": isSelected, "blue": !isSelected })}
                                component={getIconComponent(item)}
                            />
                            {`${i + 1}. ${item}`}
                        </Button>
                    )}
                />
            </Item>
        );
    };

    return (
        <div className="feature-list">
            <DividerBar name="FEATURE LIST" />
            <div className="feature-list-btns">
                <Radio.Group size="small" onChange={handleChangeValue} value={value} buttonStyle="solid">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="paths">Paths</Radio.Button>
                    <Radio.Button value="waypoints">Waypoints</Radio.Button>
                    <Radio.Button value="polygons">Polygons</Radio.Button>
                </Radio.Group>
                <Button type="link" onClick={handleReset}>Reset</Button>
            </div>
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={filterData()}
                renderItem={renderItem}
                pagination={{
                    position: "bottom",
                    simple: true,
                    total: allFeatures.length,
                    pageSize: 10,
                }}
            />
        </div>

    );
};

export default FeaturesList;
